<template>
    <div class="card card-f-h flex flex-col">
        <el-table v-if="filesData.length > 0" :data="filesData" style="width: 100%">
            <el-table-column prop="custom_id" width="80" />
            <el-table-column :label="$t('rut_files.amount')" sortable>
                <template slot-scope="scope">
                    {{ intToMoneyFormat(scope.row.total_amount) }}
                </template>
            </el-table-column>
            <el-table-column prop="status" :label="$t('rut_files.status')" sortable>
                <template slot-scope="scope">
                    {{ $t(`rut_files.${scope.row.status}`) }}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="updated_at" :label="$t('rut_files.sent_at')" sortable width="130"></el-table-column> -->
            <el-table-column prop="created_at" :label="$t('rut_files.created_at')" sortable width="130" />
            <el-table-column width="100">
                <template slot-scope="scope">
                    <el-button-group>
                        <!-- <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="top">
                            <router-link
                                :to="'/economy/rut_files/' + scope.row.id + '/details'"
                                class="el-button fas fa-search el-tooltip item el-button--primary el-button--mini"
                            />
                        </el-tooltip> -->
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('rut_files.mark_as_sent')" placement="top">
                            <el-button
                                :disabled="!userCan('update rut files') || scope.row.status === 'sent'"
                                type="primary"
                                size="mini"
                                @click="handleChangeStatus(scope.row.id)"
                            >
                                <fa-icon :icon="['far', 'sync']" />
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.download')" placement="top">
                            <el-button type="primary" class="fal fa-download" size="mini" @click="handleDownload(scope.row.uuid)">
                                <fa-icon :icon="['fas', 'download']" />
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                            <el-button :disabled="! userCan('delete rut files')" type="danger" size="mini" @click="handleDelete(scope.row.uuid)">
                                <fa-icon :icon="['fas', 'trash']" />
                            </el-button>
                        </el-tooltip>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
export default {
    data() {
        return {
            noDataInformation: false,
            filesData:         [],
            filesDataOrigin:   [],
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_rut_files');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('economy.rut_files'));
        this.$store.commit('setActiveMenuItem', '6-4');

        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/economy/rut_files`);

            this.filesData = Object.freeze(data);
            this.filesDataOrigin = Object.freeze(data);
            this.noDataInformation = data.length;
            this.$wait.end('loading');
            this.$wait.end('loading.initial_rut_files');
        },

        async handleDownload(uuid) {
            window.open(`${process.env.VUE_APP_CORE_URL}rut_files/${uuid}/download`);
        },

        async handleChangeStatus(id) {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/economy/rut_files/${id}/mark_as_sent`);
            this.$notify.success({ title: this.$t('common.success') });
            this.getAll();
        },

        async handleDelete(uuid) {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        // @Filip
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;

                        try {
                            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/economy/rut_files/${uuid}`);
                            this.$notify.success({ title: 'Success' });
                            this.getAll();
                        } catch {
                            return;
                        }

                        done();
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                    } else {
                        done();
                    }
                },
            });
        },

    },
};
</script>
